import React from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

function LinkedText ({
    content,
    links
}) {

    const splitText = (content, links) => {
        const linkTexts = Object.keys(links);

        const cleanedName = (name) => name.includes("_")? name.replaceAll("_", '.'): name;

        const cleanedNames = linkTexts.map(name => cleanedName(name));
        console.log(cleanedNames)
        console.log(linkTexts)

        let parts = [content];

        linkTexts.forEach((linkText, idx) => {
            const cleanedLinkText = cleanedNames[idx];
            const linkHref = links[linkText];

            parts = parts.flatMap(part => {
                if (typeof part === 'string') {
                    // Split by either the original linkText or the cleaned version
                    return part.split(cleanedLinkText).flatMap((splitPart, index, array) => {
                        if (index === array.length - 1) {
                            return [splitPart];
                        }
                        // Display the original linkText and set the href to the link
                        return [splitPart, <a className='linkedText' href={linkHref} key={`${cleanedLinkText}-${index}`}>{cleanedLinkText}</a>];
                    });
                }
                return [part];
            });
        });

        return parts;
    };


    return (
        <div>
            {content.split("//").map((section, sectionIndex) => (
                <div className='flex'>
                    <p style={{fontSize: "20px"}}>●</p>
                    <p style={{fontSize: "20px", margin: "0px 8px 12px 8px"}} key={sectionIndex}>
                        {splitText(section, links)}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default LinkedText
