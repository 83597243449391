import { useNavigate, NavLink } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from "../context/AppContext";
import helper from '../QueryObjects/Helper';


function SearchGroupPanel({
    searchGroups,
    cardClass
}) {

    const [sortedGroups, setSortedGroups] = useState({
        damage: [],
        cardGain: [],
        playerBuff: [],
        playerDebuff: [],
        target: [],
        cardChange: [],
        deckChange: [],
        boardChange: [],
        playerAction: [],
        activationTiming: [],
        resolution: [],
        effectType: [],
        cost: []
    })
    const [show, setShow] = useState(false);

    const handleShow = async() => {
        setShow(!show)
    };

    const getGroupCategories = () => {
        let categories = {
            damage: [],
            cardGain: [],
            playerBuff: [],
            playerDebuff: [],
            target: [],
            cardChange: [],
            deckChange: [],
            boardChange: [],
            playerAction: [],
            activationTiming: [],
            resolution: [],
            effectType: [],
            cost: []
        }
        for (let group of searchGroups) {
            const category = group.groupCategory
            categories[category].push(group);
            console.log(categories)
        }
        console.log(categories)
        setSortedGroups(categories)
    }

    useEffect(() => {
        getGroupCategories();
    // eslint-disable-next-line
    },[searchGroups]);

    return (
        <div>
            <div className={cardClass ? `big${cardClass} margin-top-8` : "bigNoClass margin-top-8"}>
                <span style={{display: "flex", justifyContent: "space-between"}}>
                    <h3 style={{fontWeight: "600", margin: "12px"}}>Card Groupings</h3>
                    { show ?
                        <h5 className="hide-show"
                            onClick={handleShow}
                        >
                            &nbsp;[Hide]
                        </h5> :
                        <h5 className="hide-show"
                            onClick={handleShow}
                        >
                            &nbsp;[Show]
                        </h5>
                    }
                </span>
                {show?
                    <div style={{marginBottom: "20px", marginTop: "-5px"}}>
                        {sortedGroups && sortedGroups.damage.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Damage</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.damage.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.cardGain.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Card Gain</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.cardGain.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.playerBuff.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Player/Card Buffs</h4>
                                <div className="card-pool-fill5">
                                    {sortedGroups.playerBuff.map((group) =>
                                        <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                            <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                            {group.name} *</h5>
                                        </NavLink>
                                        )
                                    }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.playerDebuff.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Player/Card Debuffs</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.playerDebuff.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.target.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Target</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.target.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.cardChange.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Card Change</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.cardChange.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.deckChange.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Deck Change</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.deckChange.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.boardChange.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Play-Area Change</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.boardChange.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.playerAction.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Player Action</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.playerAction.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.activationTiming.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Activation Timing</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.activationTiming.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.resolution.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Resolution Effect</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.resolution.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.effectType.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Effect Type</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.effectType.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.cost.length > 0?
                            <>
                                <h4 style={{fontWeight: "600", margin: "10px 12px"}}>Cost</h4>
                                <div className="card-pool-fill5">
                                {sortedGroups.cost.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                    </div>
                :null}
            </div>
        </div>
    )
}


export default SearchGroupPanel;
