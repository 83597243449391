import { useState, useEffect, useContext } from "react";
import Table from "../../display/Table";


function CardTagsPage(props) {

    const { card_tags } = props
    const [cardTags, setCardTags ] = useState([]);
    const getCardTags = async() =>{
        const sortedData = [...card_tags].sort((a,b) => a.name.localeCompare(b.name));
        setCardTags(sortedData.filter(item => item.tag_number !== 1000));
    };

    useEffect(() => {
        window.scroll(0, 0);
        getCardTags();
        document.title = "Card Tags - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);


    return (
        <div className="white-space">
            <h1 className="left-h1-2">Card Tags</h1>
            <Table list={cardTags}
                link={"cardtags"}
            />
        </div>
    );
}

export default CardTagsPage;
