import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import Table from "../../display/Table";


function ReactionsPage(props) {

    const {reactionProps} = props
    const [reactions, setReactions ] = useState([]);

    const getReactions = async() =>{
        const sortedData = [...reactionProps].sort((a,b) => a.name.localeCompare(b.name));
        for (let reaction of sortedData) {
            reaction["rules"] = reaction["rules"].replace("{count}", `X, where "X" is the count of ${reaction["name"]}`)
            console.log(reaction)
        }
        setReactions(sortedData.filter(item => item.tag_number !== 1000));
    };

    useEffect(() => {
        window.scroll(0, 0);
        getReactions();
        document.title = "Reactions - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);

    return (
        <div className="white-space">
            <h1 className="left-h1-2">Reactions</h1>
            <Table list={reactions}
                link={"reactions"}
            />
        </div>
    );
}

export default ReactionsPage;
