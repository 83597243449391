import { useState, useEffect, useContext } from "react";
import { BuilderQueryContext } from "../../context/BuilderQueryContext.js";
// import ImageViewListInput from "../Builder/ImageViewListInput.js";
import ImageViewInput from "../../Cards/ImageViewInput.js";
import CardSearch from "../../Cards/CardSearch.js";
import { AuthContext } from "../../context/AuthContext.js";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import PopOver from "../../display/PopOver.js";


function CardTipsCreate({
    booster_sets
}) {

    const { card_number } = useParams()
    const { account } = useContext(AuthContext)
    const {
        query,
        sortState,
        boosterSet,
        rarity,
        listView,
        showMore,
        setShowMore
    } = useContext(BuilderQueryContext)

    const [cardTips, setCardTips] = useState({
        card_name: "",
        card_number: 0,
        card_class: "",
        card_type: "",
        content: "This card can be searched by Swiss Army Knife.//Use the second effect to predetermine what cards you draw or unfurl.//This card can be used to reset Precious Potted Plant, so you can have it count as 1 additional class.",
        links: ""
    });

    const [selectedCard, setSelectedCard] = useState("")

    const [links, setLinks] = useState([]);
    const [cards, setCards] = useState([]);
    const [noCards, setNoCards] = useState(false);

    const [stayHere, setStayHere] = useState(false)
    const [showPool, setShowPool] = useState(true);
    const [showLinks, setShowLinks] = useState(true);
    const [cardNames, setCardNames] = useState([])

    const getCards = async() =>{
        const response = await fetch("https://pm-deck-react-only.onrender.com/cards")
        const data = await response.json();
        console.log(data)
        if (data.length == 0 ) {
            setNoCards(true)
        }
        const sortedCards = [...data].sort(sortMethods[sortState].method);
        setCards(sortedCards);

        if (card_number) {
            const cardNumber = parseInt(card_number, 10)
            const selectedCardData = data.find(card => card.card_number === cardNumber)
            setSelectedCard(selectedCardData?? "")
            setCardTips({
                ...cardTips,
                ["card_name"]: selectedCardData.name,
                ["card_number"]: selectedCardData.card_number,
                ["card_class"]: selectedCardData.card_class,
                ["card_type"]: matchCardType(selectedCardData)
            })
        }
    };

    useEffect(() => {
        window.scroll(0, 0);
        document.body.style.overflow = 'auto';
        getCards();
        document.title = "Card Tips Create - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);

    const sortMethods = {
        none: { method: (a,b) => a.card_number - b.card_number },
        newest: { method: (a,b) => b.id.localeCompare(a.id) },
        oldest: { method: (a,b) => a.id.localeCompare(b.id) },
        name: { method: (a,b) => a.name.localeCompare(b.name) },
        card_number: { method: (a,b) => a.card_number - b.card_number },
        enthusiasm_highest: { method: (a,b) => b.enthusiasm - a.enthusiasm },
        enthusiasm_lowest: { method: (a,b) => a.enthusiasm - b.enthusiasm },
    };

    const all_cards = cards.filter(card => card.name.toLowerCase().includes(query.cardName.toLowerCase()))
        .filter(card => (card.effect_text + card.second_effect_text).toLowerCase().includes(query.cardText.toLowerCase()))
        .filter(card => card.card_number.toString().includes(query.cardNumber))
        .filter(card => card.hero_id.toLowerCase().includes(query.heroID.toLowerCase()))
        .filter(card => card.series_name.toLowerCase().includes(query.series.toLowerCase()))
        .filter(card => card.card_number >= query.startingNum)
        .filter(card => query.type? card.card_type.some(type => type.toString() == query.type):card.card_type)
        .filter(card => card.card_class.includes(query.cardClass))
        .filter(card => query.extraEffect? card.extra_effects.some(effect => effect.toString() == query.extraEffect):card.extra_effects)
        .filter(card => query.reaction? card.reactions.some(reaction => reaction.toString() == query.reaction):card.reactions)
        .filter(card => query.tag? card.card_tags.some(tag => tag.toString() == query.tag):card.card_tags)
        .filter(card => boosterSet && !rarity ? boosterSet.all_cards.includes(card.card_number):card.card_number)
        .filter(card => boosterSet && rarity ? boosterSet[rarity].includes(card.card_number):card.card_number)
        .sort(sortMethods[sortState].method)

    const matchCardType = (card) => {
        if (card.card_type[0] === 1001) {
            return "Fighter"
        }
        else if (card.card_type[0] === 1002) {
            return "Aura"
        }
        else if (card.card_type[0] === 1003) {
            return "Move"
        }
        else if (card.card_type[0] === 1004) {
            return "Ending"
        }
        else if (card.card_type[0] === 1005) {
            return "Any Type"
        }
        else if (card.card_type[0] === 1006) {
            return "Item"
        }
        else if (card.card_type[0] === 1007) {
            return "Event"
        }
        else if (card.card_type[0] === 1008) {
            return "Comeback"
        }
    }

    const handleSelectedCard = (event) => {
        const cardNumber = parseInt(event.target.value, 10)
        const selectedCardData = cards.find(card => card.card_number === cardNumber)
        setCardTips({
            ...cardTips,
            ["card_name"]: selectedCardData.name,
            ["card_number"]: selectedCardData.card_number,
            ["card_class"]: selectedCardData.card_class,
            ["card_type"]: matchCardType(selectedCardData)
        })
        setSelectedCard(selectedCardData)
        console.log(cardTips)
    }

    const handleShowMore = (event) => {
        setShowMore(showMore + 20)
    };

    const handleChange = (event) => {
        setCardTips({ ...cardTips, [event.target.name]: event.target.value });
        console.log(selectedCard)
    };

    const handleClick = (card) => {
        console.log(links.filter(link => link.card_number === card.card_number))
        if (links.filter(link => link.card_number === card.card_number).length === 0) {
            setLinks([...links, card])
            console.log(cardNames)
            setCardNames([...cardNames, card.name])
        }
    }

    const handleRemoveCard = (card) => {
        const linksIndex = links.indexOf(card);
        const newLinkList = [...links];
        newLinkList.splice(linksIndex, 1);
        setLinks(newLinkList);

        const nameIndex = cardNames.indexOf(card.name);
        const newCardNames = [...cardNames];
        newCardNames.splice(nameIndex, 1);
        setCardNames(newCardNames)
    }

    const clearLinks = () => {
        setCardTips({
            card_name: "",
            card_number: 0,
            card_class: "",
            card_type: "",
            content: "",
            links: ""
        })
        setLinks([])
        setCardNames([])
    }

    const addName = (text) => {
        // Get the current position of the cursor
        const inputElement = document.getElementById("content")
        const start = inputElement.selectionStart;
        const end = inputElement.selectionEnd;

        // Insert the new text at the cursor position
        inputElement.value = inputElement.value.slice(0, start) + text + inputElement.value.slice(end);

        // Move the cursor to the end of the inserted text
        inputElement.selectionStart = inputElement.selectionEnd = start + text.length;

        // Focus the input element again (optional, to make sure the cursor stays in the input field)
        inputElement.focus();
        setCardTips({...cardTips, ["content"]: inputElement.value})
    }


    const handleStayCheck = (event) => {
        setStayHere(!stayHere);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {...cardTips};
        const linkObject = {}
        const cleanedName = (name) => name.includes(".")? name.replaceAll(".", '_'): name;
        for (let card of links){
            if (!linkObject[cleanedName(card.name)]) {
                linkObject[cleanedName(card.name)] = `https://www.jothplaymaker.com/cards/${card.card_number}`
            }
        }
        data["links"] = linkObject;
        console.log(data)
        const tipUrl = "https://pm-deck-react-only.onrender.com/card_tips/";
        // const tipUrl = "http://localhost:4000/card_tips/";
        const fetchConfig = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        };
        if (data["card"] !== "" && data["card_number"] !== "") {
            const response = await fetch(tipUrl, fetchConfig);
            if (response.ok) {
                const responseData = await response.json();
                clearLinks()
                setSelectedCard("")
                if (!stayHere) {navigate(`/cardtips/${selectedCard.card_number}`)}
                console.log("Success", responseData)
            } else {
                alert("Error in creating tip");
            }
        }
    }

    const handleShowPool = (event) => {
        setShowPool(!showPool);
    };

    const handleShowLinks = (event) => {
        setShowLinks(!showLinks);
    };

    const preprocessText = (text) => {
        return text.split("//").join("\n");
    };

    const isQueryEmpty = Object.values(query).every((value) => value === "");

    const navigate = useNavigate()
    if (!(account && account.roles.includes("admin"))) {
        setTimeout(function() {
            navigate("/")
        }, 3000);
    }

    const [hoveredCard, setHoveredCard] = useState(null)

    return (
        <div>
            { account && account.roles.includes("admin")?
                <div className="white-space">
                    <PopOver
                        content={hoveredCard}
                        setObject={setHoveredCard}
                    />
                    <h1 className="margin-top-40">Tips Create</h1>
                    <div className="space-around media-display">
                        <div className="flex-column-items">
                            <div id="create-cardTips-page">
                                <h2 className="left">Tips Details</h2>
                                <h5 className="label">Card </h5>
                                <select
                                    className="builder-input"
                                    type="text"
                                    placeholder=" Card"
                                    onChange={handleSelectedCard}
                                    name="selectedCard"
                                    value={selectedCard.card_number}>
                                    <option value="">Card</option>
                                    {cards.map(function(card, index)
                                        {return( <option value={card.card_number}
                                            key={index.toString() + card.card_number.toString()}
                                            >{card.name}</option>)}
                                        )}
                                </select>
                                <br/>
                                <div className="flex builder-input">
                                    <div className="flex-full">
                                        <input
                                            style={{margin: "2px 5px 0 2px", height:"10px"}}
                                            id="stayHere"
                                            type="checkbox"
                                            onChange={handleStayCheck}
                                            name="stayHere"
                                            checked={stayHere}
                                            >
                                        </input>
                                        <label for="stayHere"
                                            className="bold"
                                        >
                                            Keep me here
                                        </label>
                                    </div>
                                </div>
                                <button
                                    className="left"
                                    onClick={handleSubmit}
                                    disabled={(
                                        cardTips["card_name"] !== ""
                                        && cardTips["card_number"] !== 0)?
                                            false: true
                                    }
                                >
                                    Create Tips
                                </button>
                                <button
                                    className="left"
                                    onClick={clearLinks}
                                >
                                    Clear List
                                </button>
                            </div>
                            <br/>
                        </div>
                        <div className="flex-column-full">
                            {selectedCard ? (
                                <img
                                    className="cover-card"
                                    src={selectedCard.picture_url}
                                    alt={selectedCard.name}
                                    variant="bottom"/>
                                    ):(
                                <img
                                    className="cover-card"
                                    src={"https://i.imgur.com/krY25iI.png"}
                                    alt="Card"
                                    variant="bottom"/>)}
                        </div>
                        <div className="flex-column-full">
                            <CardSearch
                                boosterSets={booster_sets}
                            />
                        </div>
                    </div>
                    <h2 className="label">Tips Content</h2>
                    <textarea
                        className="large-article"
                        type="text"
                        id="content"
                        placeholder=" Tips Content"
                        onChange={handleChange}
                        name="content"
                        value={cardTips.content}>
                    </textarea>
                    <br/>

                    <div className="flex-wrap margin-10">
                        {cardNames.map((name, index) => (
                            <h5 key={index} className="pointer underline"
                                style={{marginRight: index === cardNames.length - 1? "0px" : "15px"}}
                            onClick={() => addName(name)}
                            >
                            {name}
                            </h5>
                        ))}
                    </div>

                    <div className={showPool ? "rarities2" : "no-rarities"}>
                        <div style={{marginLeft: "0px"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0px 1% 20px", fontWeight: "700"}}
                                >Card Pool</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {all_cards.length > 0 ?
                                    <h5
                                        className="left db-pool-count"
                                    >{all_cards.length}</h5>:
                                    null}
                                { showPool ?
                                    <h5 className="left db-pool-count"
                                        onClick={() => handleShowPool()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className="left db-pool-count"
                                        onClick={() => handleShowPool()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            <div className={showPool ? "scrollable" : "hidden2"}>
                                <div className="cardPoolColumn" style={{margin: "8px"}}>

                                { all_cards.length == 0 && isQueryEmpty && !noCards?
                                    <div className="loading-container">
                                        <div className="loading-spinner"></div>
                                    </div> :
                                null}

                                <div className="card-pool-fill">
                                    {all_cards.slice(0, showMore).map((card) => {
                                        return (
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <img
                                                    onClick={() => handleClick(card)}
                                                    className={links.filter(link => link.card_number === card.card_number).length > 0 ?
                                                        "builder-card glow3 greyScale" :
                                                        "builder-card pointer glow3"}
                                                    // title={`${card.name}\n${preprocessText(card.effect_text)}\n${card.second_effect_text ? preprocessText(card.second_effect_text) : ""}`}
                                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                    onMouseEnter={() => setHoveredCard(card)}
                                                    onMouseLeave={() => setHoveredCard(null)}
                                                    alt={card.name}/>
                                            </div>
                                        );
                                    })}
                                </div>
                                {showMore < all_cards.length ?
                                    <div className="flex-content">
                                        <button
                                            style={{ width: "100%", margin:".5% 4px .5% 4px"}}
                                            onClick={handleShowMore}>
                                            Show More Cards ({all_cards.length - showMore} Remaining)
                                        </button>
                                    </div> : null
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        listView?
                        <div className="deck-list">
                            <div className="links">
                            <div style={{marginLeft: "20px"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <h2
                                        className="left"
                                        style={{margin: "2% 0% 1% 0%", fontWeight: "700"}}
                                    >Links</h2>
                                    <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                    {links.length > 0 ?
                                    <h5
                                        className="left"
                                        style={{margin: "1% 0%", fontWeight: "700"}}
                                    >{links.length}</h5>:
                                    null}
                                </div>
                                {links.length > 0 ?
                                    <>
                                        {links.sort((a,b) => a.card_number - b.card_number).map((card) => {
                                            return (
                                                <div style={{padding: "5px"}}>
                                                    <div className="card-container pointer">
                                                        <h5 onClick={() => handleRemoveCard(card)}>{card.name}</h5>
                                                        <img
                                                            className="card-image"
                                                            src={card.picture_url}
                                                            alt={card.name}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>:
                                <h4 className="left no-cards">No cards added</h4>}
                            </div>
                        </div>
                        </div>
                    :

                        <ImageViewInput
                            title={"Linked Cards"}
                            cardList={links}
                            show={showLinks}
                            handleShow={handleShowLinks}
                            handleRemoveCard={handleRemoveCard}
                        />

                    }
                </div>:
                <div className="textwindow">
                    <h1 className="undercontext">This Feature Is For Admins Only</h1>
                    <h3 className="undercontext">Redirecting in 3 Seconds</h3>
                </div>
            }
        </div>
    );
}

export default CardTipsCreate;
