import React, { useEffect, useState} from 'react';
import helper from '../QueryObjects/Helper';
import './PopOver.css';

const PopOver = ({
    content,
    setObject
}) => {

    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event) => {
        setPosition({
            x: event.clientX,
            y: event.clientY - 20,
        });
        if (
            !event.target.closest(".popOver") &&
            !event.target.closest(".builder-card") &&
            !event.target.closest(".ultra") &&
            !event.target.closest(".ultra2") &&
            !event.target.closest(".cd-related-modal-card") &&
            !event.target.closest(".dd-count2") &&
            !event.target.closest(".relative") &&
            !event.target.closest(".trigger")
        ) {
            setObject(null); // Clear the hover state if clicked outside
        }
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    const preprocessText = (text) => {
        return text.split("//");
    };


    return (
        <>
        {content? (
            <div
                className="popOver noHover"
                style={{ top: position.y, left: position.x }} // Offset by 10px for visibility
            >
                <div className="flex">
                    <p className="popOver1">{content.name} -</p>
                    {content.card_class?
                        <p className="popOver1">{content.card_class}</p>: null
                    }
                    <p className="popOver1">{helper.getCardType(content)}</p>
                </div>
                {preprocessText(content.effect_text)?.map(line => {
                    return <p className="popOver2">{line}</p>
                })}
                {preprocessText(content.second_effect_text)?.map(line => {
                    return <p className="popOver3">{line}</p>
                })}
                <p className="popOver3">
                    {content.series_name.replaceAll("//", " | ")} &nbsp; {content.card_number}
                    <span style={{fontSize: "16px"}}>&#11089;</span>
                    {content.hero_id}
                </p>
            </div>
        ) : null}
        </>
    );
};

export default PopOver;
