import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { HowToQueryContext } from "../../context/HowToQueryContext";
import { AuthContext } from "../../context/AuthContext";


function HowTosPage() {
    const {account} = useContext(AuthContext)

    const {
        howToQuery,
        setHowToQuery,
        howToSortState,
        setHowToSortState,
        handleResetHowToQuery,
    } = useContext(HowToQueryContext)

    const [howTos, setHowTos] = useState([]);
    const [loading, setLoading] = useState(false)

    const getHowTos = async() =>{
        setLoading(true)
        const howToResponse = await fetch("https://pm-deck-react-only.onrender.com/how_tos/")
        // const howToResponse = await fetch("http://localhost:4000/how_tos/")
        if (howToResponse.ok) {
            const howToData = await howToResponse.json();
            const filteredHowToData = account && account.roles.includes("admin")?
                howToData: howToData.filter(howTo => howTo.in_progress? howTo.in_progress === false: true )
            setHowTos(filteredHowToData.sort((a,b) => a.how_to_number - b.how_to_number))
        }
        setLoading(false)
    }

    const howToColors = {
        beginner: "rgba(42, 168, 115, 0.70)",
        advanced: "rgba(192, 145, 17, 0.87)",
        expert: "rgba(124, 19, 33, 0.70)",
        admin: "rgba(77, 71, 94, 0.50)",
    }

    const howToBorders = {
        beginner: "rgb(54, 184, 129)",
        advanced: "#f0be1c",
        expert: "rgb(255, 0, 43)",
        admin: "#4D475E",
    }

    useEffect(() => {
        window.scroll(0, 0);
        document.body.style.overflow = 'auto';
        getHowTos();
        document.title = "Rulebooks - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);

    const howToSortMethods = {
        none: {method: (a,b) => a.how_to_number - b.how_to_number},
        newest: {method: (a,b) => b.id.localeCompare(a.id)},
        oldest: {method: (a,b) => a.id.localeCompare(b.id)},
    };

    const handleHowToQuery = (event) => {
        setHowToQuery({ ...howToQuery, [event.target.name]: event.target.value });
    };

    const handleProgressCheck = (event) => {
        setHowToQuery({...howToQuery, in_progress: !howToQuery.in_progress});
    };

    const handleHowToSortState = (event) => {
        setHowToSortState(event.target.value);
    };

    const filteredHowTos = howTos.filter(howTo => howToQuery.game_format? (howToQuery.game_format === howTo.game_format): howTo)
        .filter(howTo => howToQuery.skill_level? (howToQuery.skill_level === howTo.skill_level): howTo)
        .filter(howTo => howToQuery.content? (howTo.content.toLowerCase().includes(howToQuery.content.toLowerCase())): howTo)
        .filter(howTo => howToQuery.title? (howTo.title.toLowerCase().includes(howToQuery.title.toLowerCase())): howTo)
        .filter(howTo => howToQuery.in_progress? (howTo.in_progress === true): howTo)
        .sort(howToSortMethods[howToSortState].method)

    return (
        <div className="white-space">
            {/* <span className="media-flex-center"> */}
                <div className="wide400p">
                    <h1 className="left-h1">Rulebook List</h1>
                    <input
                        className="left dcbsearch-x-large"
                        type="text"
                        placeholder=" Title Contains..."
                        name="title"
                        value={howToQuery.title}
                        onChange={handleHowToQuery}>
                    </input>
                    <br/>
                    <input
                        className="left dcbsearch-x-large"
                        type="text"
                        placeholder=" Rulebook Contains..."
                        name="content"
                        value={howToQuery.content}
                        onChange={handleHowToQuery}>
                    </input>
                    <br/>
                    <div className="flex-items">
                        <select
                            className="left dcbsearch-medium"
                            type="text"
                            placeholder=" Game Format"
                            name="game_format"
                            value={howToQuery.game_format}
                            onChange={handleHowToQuery}>
                            <option value="">Game Format</option>
                            <option value="Standard">Standard</option>
                        </select>
                        <select
                            className="left dcbsearch-medium"
                            type="text"
                            placeholder=" Skill Level"
                            name="skill_level"
                            value={howToQuery.skill_level}
                            onChange={handleHowToQuery}>
                            <option value="">Skill Level</option>
                            <option value="beginner">Beginner</option>
                            <option value="advanced">Advanced</option>
                            <option value="expert">Expert</option>
                        </select>
                        <select
                            className="left dcbsearch-medium"
                            type="text"
                            placeholder=" Sorted By"
                            value={howToSortState}
                            onChange={handleHowToSortState}>
                            <option value="none">Sorted By</option>
                            <option value="newest">Newest</option>
                            <option value="oldest">Oldest</option>
                        </select>
                    </div>
                    { account && account.roles.includes("admin")?
                        <div className="flex-items margin-top-10">
                            <input
                                style={{margin: "2px 5px 0 7px", height:"10px"}}
                                type="checkbox"
                                onChange={handleProgressCheck}
                                name="news"
                                checked={howToQuery.in_progress}
                                >
                            </input>
                            <label for="news"
                                className="bold"
                            >
                                In Progress Only
                            </label>
                        </div>: null
                    }
                    <div className="flex">
                        { account && account.roles.includes("admin")?
                            <NavLink to="/howtocreate">
                                <button
                                    className="left red margin-top-10">
                                    Create
                                </button>
                            </NavLink>:
                        null}
                        <button
                            className="left margin-top-10"
                            variant="dark"
                            onClick={handleResetHowToQuery}
                            >
                            Reset Filters
                        </button>
                    </div>
                    { loading ?
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                        </div> :
                        <h4 className="left-h3">
                            {filteredHowTos.length > 0?
                                `Showing Results 1 - ${filteredHowTos.length} of ${filteredHowTos.length}`:
                                "No Rulebook Fits Your Search Criteria"}
                        </h4>
                    }
                </div>
            {/* </span> */}

            <br/>
            <div className="newsPage">
                {filteredHowTos.map((howTo, index) => {
                    return (
                        <>
                            <NavLink className="nav-link no-pad" to={`/rulebooks/${howTo.id}`}>
                                <div
                                    className="flex-items newsItem"
                                    style={{
                                        backgroundColor: howTo.in_progress && howTo.in_progress === true?
                                            howToColors["admin"]:
                                            howToColors[howTo.skill_level]
                                        ,
                                        borderColor: howTo.in_progress && howTo.in_progress === true?
                                            howToBorders["admin"]:
                                            howToBorders[howTo.skill_level]
                                        ,
                                        marginTop: index === 0 ? "2px" : "10px",
                                        marginBottom: index ===  filteredHowTos.length -1 ? "2px" : "10px"
                                    }}
                                >

                                    <h3 className="newsText no-wrap">{howTo.game_format}</h3>
                                    <img className="skill_level" src={`${howTo.skill_level}.png`} alt={`${howTo.skill_level}.png`}/>
                                    {/* <h4 className="newsText">{story.section}</h4> */}
                                    <h4 className="newsText6">{howTo.title}</h4>
                                </div>
                            </NavLink>
                        </>
                    )
                })}
            </div>
            {/* {someMoreNews < completelyFilteredNews.length ?
                <button
                    style={{ width: "100%", marginTop:"2%"}}
                    onClick={handleSomeMoreNews}>
                    Show More HowTos ({completelyFilteredNews.length - someMoreNews} Remaining)
                </button> : null } */}
        </div>
    );
}

export default HowTosPage;
