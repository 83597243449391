import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import PopOver from "../../display/PopOver";


function CardTipsList() {

    const { account } = useContext(AuthContext)

    const [noCardTips, setNoCardTips] = useState(false)
    const [cardTips, setCardTips] = useState([])

    const [cards, setCards] = useState([])

    const getCardTips = async() => {
        const cardsResponse = await fetch("https://pm-deck-react-only.onrender.com/cards")
        const cardsData = await cardsResponse.json()
        setCards(cardsData)

        const cardTipsResponse = await fetch("https://pm-deck-react-only.onrender.com/card_tips/")
        const cardTipsData = await cardTipsResponse.json()
        if (cardTipsData.length > 0) {
            const sortedCardTips = cardTipsData.sort((a,b) => a.card_name.localeCompare(b.card_name))
            setCardTips(sortedCardTips)
            setNoCardTips(false)
        } else {
            setNoCardTips(true)
        }
        console.log(cardTips)
    }

    const tipCount = (cardTip) => {
        return cardTip.content.split("//").length
    }

    const getCard = (cardNumber) => {
        if (cardNumber) {
            const card = cards.find(cardItem => cardNumber === cardItem.card_number)
            return card
        }
    }

    useEffect(() => {
        window.scroll(0, 0);
        getCardTips()
        document.title = "Card Tips - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);

    const [hoveredCard, setHoveredCard] = useState(null)

    return (
        <div className="white-space">
            <PopOver
                content={hoveredCard}
                setObject={setHoveredCard}
            />
            <h1 className="left-h1-2">Card Tips</h1>
            { account && account.roles.includes("admin")?
                <NavLink to="/tipcreate">
                    <button
                        className="left red margin-left-13">
                        Create
                    </button>
                </NavLink>:
            null}
            {!noCardTips?
                <div className="card-list2 margin-bottom-20">
                    {cardTips.map((cardTip) => {
                        return (
                            <NavLink to={`/cardtips/${cardTip.card_number}`}
                                className="nav-link2 glow2 trigger"
                                onMouseEnter={() => setHoveredCard(getCard(cardTip.card_number))}
                                onMouseLeave={() => setHoveredCard(null)}
                            >
                                <div className={cardTip.card_class ? `big${cardTip.card_class}5 flex-items` : "bigNoClass2 flex-items"}>
                                    <div className="flex-baseline cardTip">
                                        <h3 className="ellipsis" style={{fontWeight: "600", margin: "0px 12px"}}>{cardTip.card_name}</h3>
                                        <h5 className="ellipsis" style={{fontWeight: "600", margin: "0"}}>{cardTip.card_type} ({tipCount(cardTip)})</h5>
                                    </div>
                                </div>
                            </NavLink>
                        );
                    })}
                </div>
            :null}
        </div>
    );
}

export default CardTipsList;
