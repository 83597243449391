import React from 'react';


function ImageViewInput({
    title,
    cardList,
    show,
    handleShow,
    handleRemoveCard,
}) {

    const preprocessText = (text) => {
        return text.split("//").join("\n");
    };

    return (
        <div className="support">
            <div>
                <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                    <h2
                        className="left"
                        style={{margin: "1% 0%", fontWeight: "700"}}
                    >{title}</h2>
                    <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                    {cardList.length > 0 ?
                    <h5
                        className="left"
                        style={{margin: "1% 0%", fontWeight: "700"}}
                    >{cardList.length}</h5>:
                    null}
                    { show ?
                        <h5 className={cardList.length > 0 ? "left db-main-count" : "hidden2"}
                            onClick={() => handleShow()}>
                                &nbsp;[Hide]
                        </h5> :
                        <h5 className={cardList.length > 0 ? "left db-main-count" : "hidden2"}
                            onClick={() => handleShow()}>
                            &nbsp;[Show]
                        </h5>}
                </div>

                {cardList.length > 0 ?
                    <div className={show ? "card-pool-fill2": "hidden2"}>
                        {cardList.sort((a,b) => a.card_number - b.card_number).map((card) => {
                            return (
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <img
                                        className="builder-card2 pointer"
                                        onClick={() => handleRemoveCard(card)}
                                        // title={`${card.name}\n${preprocessText(card.effect_text)}\n${card.second_effect_text ? preprocessText(card.second_effect_text) : ""}`}
                                        src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                        alt={card.name}/>
                                </div>
                            );
                        })}
                    </div> :
                    <h4 className="left no-cards">No cards added</h4>
                }
            </div>
        </div>
    );
}

export default ImageViewInput
