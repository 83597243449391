import { useState, useEffect } from "react";
import Table from "../display/Table";

function TermsPage(props) {

    const {terms} = props

    useEffect(() => {
        window.scroll(0, 0);
        console.log(terms)
        document.body.style.overflow = 'auto';
        document.title = "Glossary and Rulings - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);


    return (
        <div className="white-space">
            <h1 className="left-h1-2">Glossary and Rulings</h1>
            <Table list={terms}/>
        </div>
    );
}

export default TermsPage;
