import LinkedText from "../display/LinkedText";
import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";


function CardRulingPage() {

    const ruling = {"_id":"66ac0fce81b3c8910e46dbe4","card_name":"Bolt Blossom","card_number":1045,"content":"● Use cards like Mirror Plant!! and Testing that returns cards to the top of the Main deck, so you can control what cards are unfurled.","links":{"Mirror Plant!!":"https://www.jothplaymaker.com/cards/1047","Testing":"https://www.jothplaymaker.com/cards/1086"},"__v":0,"id":"66ac0fce81b3c8910e46dbe4"};

    // const [ruling, setRuling] = useState({
    //     card_name: "",
    //     card_number: null,
    //     content: "",
    //     links: {}
    // })

    return (
        <div className="white-space">
            <LinkedText
                content={ruling.content}
                links={ruling.links}
            />
        </div>
    );
}

export default CardRulingPage;
