// import { db } from "../Firebase"
// import {
//     getDocs,
//     collection,
//     query,
//     orderBy,
//     where,
//     limit,
//     addDoc,
//     or,
//     startAfter
// } from "firebase/firestore"
import { useNavigate } from "react-router-dom";
import helper from "./Helper"

const noFBDeckQueries = {
    getDeckDataById: async function getDeckDataById(id) {
        const deckResponse = await fetch(`https://pm-deck-react-only.onrender.com/decks/${id}`)
        if (!deckResponse.ok) {
            console.log("No matching decks.");
            return null;
        } else {
            const deckData = await deckResponse.json()
            helper.createTimesAgos(deckData)
            return deckData;
        }
    },
    createDeck: async function createDeck(deckData) {
        const randomString = await helper.generateRandomString(24);
        const created_on = await helper.createTimeObj()

        deckData["id"] = randomString
        deckData["created_on"] = created_on
        deckData["updated_on"] = created_on

        console.log(deckData)
        const deckUrl = "https://pm-deck-react-only.onrender.com/decks";
        const fetchConfig = {
            method: "POST",
            body: JSON.stringify(deckData),
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await fetch(deckUrl, fetchConfig);
        const createdDeck = response.json()
        if (response.ok) {
            return createdDeck
        } else {
            return false
        }
    },
    editDeck: async function editDeck(id, deckData) {
        const updated_on = await helper.createTimeObj()
        deckData["updated_on"] = updated_on
        console.log(deckData)
        const deckUrl = `https://pm-deck-react-only.onrender.com/decks/${id}`;
        // const deckUrl = `http://localhost:4000/decks/${id}`;
        const fetchConfig = {
            method: "PUT",
            body: JSON.stringify(deckData),
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await fetch(deckUrl, fetchConfig);
        const editedDeck = response.json()
        if (response.ok) {
            return editedDeck
        } else {
            return false
        }
    },
    deleteDeck: async function deleteDeck(id) {
        const deckUrl = `https://pm-deck-react-only.onrender.com/decks/${id}`;
        console.log(deckUrl)
        const fetchConfig = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await fetch(deckUrl, fetchConfig);
        if (response.ok) {
            return true
        } else {
            return false
        }
    },
    getQueriedDecksData: async function getQueriedDecksData(queryList) {
        let queryString = ""
        console.log(queryList)
        for (const [key, value] of Object.entries(queryList[0])) {
            queryString += `${key}=${value}&`
        }
        queryString += `limit=${queryList[1]}`
        console.log(queryString)
        const response = await fetch(`https://pm-deck-react-only.onrender.com/decks?${queryString}`)
        console.log(`https://pm-deck-react-only.onrender.com/decks?${queryString}`)
        // const response = await fetch(`http://localhost:4000/decks?${queryString}`)
        // console.log(`http://localhost:4000/decks?${queryString}`)
        const decksData = await response.json()
        helper.createAllTimesAgos(decksData)
        console.log(decksData)

        return decksData;
    },
    // deleteDeck: async function deleteDeck(id) {
    //     const decksCollectionRef = collection(db, "decks");
    //     const deckQuery = query(
    //         decksCollectionRef,
    //         where("id", "==", id)
    //     );

    //     const snapshot = await getDocs(deckQuery);
    //     if (!snapshot.empty) {
    //         // Document exists, delete it
    //         const deckDoc = snapshot.docs[0];
    //         await deleteDoc(deckDoc.ref);
    //         return true; // Deletion successful
    //     } else {
    //         console.log("Deck not found");
    //         return false; // Deck not found
    //     }
    // },
    // getdecksDataNoDate: async function getdecksDataNoDate() {
    //     const decksCollectionRef = collection(db, "decks")
    //     const response = await getDocs(decksCollectionRef);
    //     const data = response.docs.map((doc) => ({
    //         ...doc.data(),
    //     }))
    //     return data
    // },
    // getDecksListData: async function getDecksListData(
    //     queryList,
    //     sortMethod,

    // ) {
    //     let decksCollectionRef = collection(db, "decks");
    //     // for (const [key, value] of Object.entries(queryList)) {
    //     //     if (value[2]){
    //     //         decksCollectionRef = query(decksCollectionRef, where(key, value[1], value[0]));
    //     //     }
    //     // }
    //     let needsContains = ""
    //     for (const [key, value] of Object.entries(queryList)) {
    //         if (value[2]) {
    //             if (key === "card_series_names" && value[0]) {
    //                 needsContains = value[0]
    //             } else {
    //                 decksCollectionRef = query(decksCollectionRef, where(key, value[1], value[0]));
    //             }
    //         }
    //     }
    //     if (needsContains) {
    //         decksCollectionRef = query(decksCollectionRef,
    //             or(
    //                 where("card_names", "array-contains", needsContains),
    //                 where("series_names", "array-contains", needsContains)
    //         ));
    //     }
    //     decksCollectionRef = query(
    //         decksCollectionRef,
    //         orderBy(sortMethod[0], sortMethod[1]),
    //         limit(20)
    //     )
    //     const snapshot = await getDocs(decksCollectionRef)
    //     console.log(snapshot)
    //     if (snapshot.empty) {
    //         console.log("No matching documents.");
    //         return null;
    //     } else {
    //         const lastDoc = snapshot.docs[snapshot.docs.length-1]
    //         const data = snapshot.docs.map((doc) => ({
    //             ...doc.data(),
    //         }))
    //         helper.createAllTimesAgos(data)
    //         return [data, lastDoc];
    //     }
    // },
    // getMoreDecksListData: async function getMoreDecksListData(
    //     queryList,
    //     sortMethod,
    //     lastDoc,
    //     end
    // ) {
    //     let decksCollectionRef = collection(db, "decks");
    //     let needsContains = ""
    //     for (const [key, value] of Object.entries(queryList)) {
    //         if (value[2]) {
    //             if (key === "card_series_names" && value[0]) {
    //                 needsContains = value[0]
    //             } else {
    //                 decksCollectionRef = query(decksCollectionRef, where(key, value[1], value[0]));
    //             }
    //         }
    //     }
    //     if (needsContains) {
    //         decksCollectionRef = query(decksCollectionRef,
    //             or(
    //                 where("card_names", "array-contains", needsContains),
    //                 where("series_names", "array-contains", needsContains)
    //         ));
    //     }
    //     decksCollectionRef = query(
    //         decksCollectionRef,
    //         orderBy(sortMethod[0], sortMethod[1]),
    //     )
    //     if (lastDoc) {
    //         decksCollectionRef = query(
    //             decksCollectionRef,
    //             startAfter(lastDoc)
    //         );
    //     }
    //     decksCollectionRef = query(
    //         decksCollectionRef,
    //         limit(end)
    //     );
    //     const snapshot = await getDocs(decksCollectionRef)
    //     console.log(snapshot)
    //     if (snapshot.empty) {
    //         console.log("No matching documents.");
    //         return null;
    //     } else {
    //         const lastDoc = snapshot.docs[snapshot.docs.length-1]
    //         const data = snapshot.docs.map((doc) => ({
    //             ...doc.data(),
    //         }))
    //         helper.createAllTimesAgos(data)
    //         return [data, lastDoc];
    //     }
    // },
    // getAllDecksData: async function getdecksData() {
    //     const decksCollectionRef = collection(db, "decks")
    //     const response = await getDocs(decksCollectionRef);
    //     const data = response.docs.map((doc) => ({
    //         ...doc.data(),
    //     }))
    //     return data
    // },
}

export default noFBDeckQueries
