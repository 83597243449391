import { useState, useEffect, useContext } from "react";
import Table from "../../display/Table";

function ExtraEffectsPage(props) {

    const {extra_effects} = props
    const [extraEffects, setExtraEffects ] = useState([]);

    const getExtraEffects = async() =>{
        const sortedData = [...extra_effects].sort((a,b) => a.name.localeCompare(b.name));
        setExtraEffects(sortedData.filter(item => item.tag_number !== 1000));
    };

    useEffect(() => {
        window.scroll(0, 0);
        getExtraEffects();
        document.title = "Extra Effects - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);


    return (
        <div className="white-space">
            <h1 className="left-h1-2">Extra Effects</h1>
            <Table list={extraEffects}
                link={"extraeffects"}
            />
        </div>
    );
}

export default ExtraEffectsPage;
