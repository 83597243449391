import { useState, useEffect, useContext } from "react";
import Table from "../../display/Table";


function CardCategoriesPage(props) {

    const {card_categories} = props
    const [cardCategories, setCardCategories ] = useState([]);
    const [showClasses, setShowClasses] = useState(true);
    const [showSeries, setShowSeries] = useState(true);
    const [showSubSeries, setShowSubSeries] = useState(true);

    const getCardCategories = async() =>{
        const sortedData = [...card_categories].sort((a,b) => a.name.localeCompare(b.name));
        setCardCategories(sortedData);
    };

    useEffect(() => {
        window.scroll(0, 0);
        getCardCategories();
        document.title = "Card Categories - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);

    const handleShowClasses = () => {
        setShowClasses(!showClasses)
    }

    const handleShowSeries = () => {
        setShowSeries(!showSeries)
    }

    const handleShowSubSeries = () => {
        setShowSubSeries(!showSubSeries)
    }

    const untyped = cardCategories.filter(cardCategory =>
    cardCategory.cat_type !== "card_class" &&
    cardCategory.cat_type !== "series" &&
    cardCategory.cat_type !== "sub_series")

    const classList = []
    for (let item of cardCategories) {
        classList.push(item.name)
    }
    console.log(classList)

    return (
        <div className="white-space">
            <h1 className="left-h1-2">Card Categories</h1>

            <div className="catTable">

            <div style={{display: "flex", marginTop: "20px"}}>
                <h3 className="cat-label">Card Classes</h3>
                { showClasses ?
                    <h5 className="left db-pool-count"
                    onClick={() => handleShowClasses()}>
                            &nbsp;[Hide]
                    </h5> :
                    <h5 className="left db-pool-count"
                    onClick={() => handleShowClasses()}>
                        &nbsp;[Show]
                    </h5>}
            </div>
            {showClasses?
                <Table list={cardCategories.filter(cardCategory => cardCategory.cat_type === "card_class")}
                    link={"cardcategories"}
                    noEllipsis={true}
                />: null
            }
            <div style={{display: "flex", marginTop: "20px"}}>
                <h3 className="cat-label">Series</h3>
                { showSeries ?
                    <h5 className="left db-pool-count"
                    onClick={() => handleShowSeries()}>
                            &nbsp;[Hide]
                    </h5> :
                    <h5 className="left db-pool-count"
                    onClick={() => handleShowSeries()}>
                        &nbsp;[Show]
                    </h5>}
            </div>
            {showSeries?
                <Table list={cardCategories.filter(cardCategory => cardCategory.cat_type === "series")}
                    link={"cardcategories"}
                    noEllipsis={true}
                />: null
            }

            <div style={{display: "flex", marginTop: "20px"}}>
                <h3 className="cat-label">Sub-Series</h3>
                { showSubSeries ?
                    <h5 className="left db-pool-count"
                    onClick={() => handleShowSubSeries()}>
                            &nbsp;[Hide]
                    </h5> :
                    <h5 className="left db-pool-count"
                    onClick={() => handleShowSubSeries()}>
                        &nbsp;[Show]
                    </h5>}
            </div>
            {showSubSeries?
                <Table list={cardCategories.filter(cardCategory => cardCategory.cat_type === "sub_series")}
                    link={"cardcategories"}
                    noEllipsis={true}
                />: null
            }
            </div>
        </div>
    );
}

export default CardCategoriesPage;
