import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";


function SearchGroupsPage() {

    const { account } = useContext(AuthContext)

    const [sortedGroups, setSortedGroups] = useState({
        damage: [],
        cardGain: [],
        playerBuff: [],
        playerDebuff: [],
        target: [],
        cardChange: [],
        deckChange: [],
        boardChange: [],
        playerAction: [],
        activationTiming: [],
        resolution: [],
        effectType: [],
        cost: []
    })
    const [noSearchGroups, setNoSearchGroups] = useState(true)

    const getGroupCategories = async() => {
        const groupsResponse = await fetch("https://pm-deck-react-only.onrender.com/search_groups/")
            const groupsData = await groupsResponse.json()
            if (groupsData.length > 0) {

                const searchGroups = groupsData.sort((a,b) => a.name.localeCompare(b.name))

                let categories = {
                    damage: [],
                    cardGain: [],
                    playerBuff: [],
                    playerDebuff: [],
                    target: [],
                    cardChange: [],
                    deckChange: [],
                    boardChange: [],
                    playerAction: [],
                    activationTiming: [],
                    resolution: [],
                    effectType: [],
                    cost: []
                }
                for (let group of searchGroups) {
                    const category = group.groupCategory
                    categories[category].push(group);
                    console.log(categories)
                }
                console.log(categories)
                setSortedGroups(categories)
                setNoSearchGroups(false)
            }
    }

    useEffect(() => {
        window.scroll(0, 0);
        getGroupCategories()
        document.title = "Search Groups - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);

    // const handleShowClasses = () => {
    //     setShowClasses(!showClasses)
    // }

    // const handleShowSeries = () => {
    //     setShowSeries(!showSeries)
    // }

    // const handleShowSubSeries = () => {
    //     setShowSubSeries(!showSubSeries)
    // }

    // const untyped = searchGroups.filter(cardCategory =>
    // cardCategory.cat_type !== "card_class" &&
    // cardCategory.cat_type !== "series" &&
    // cardCategory.cat_type !== "sub_series")

    return (
        <div className="white-space">
            <h1 className="left-h1-2">Search Groups</h1>
            { account && account.roles.includes("admin")?
                    <NavLink to="/searchcreate">
                        <button
                            className="left red margin-left-13 margin-bottom-20">
                            Create
                        </button>
                    </NavLink>:
                null}

                {/* <span style={{display: "flex", justifyContent: "space-between"}}>
                    <h3 style={{fontWeight: "600", margin: "12px"}}>Card Groupings</h3>
                    { show ?
                        <h5 className="hide-show"
                            onClick={handleShow}
                        >
                            &nbsp;[Hide]
                        </h5> :
                        <h5 className="hide-show"
                            onClick={handleShow}
                        >
                            &nbsp;[Show]
                        </h5>
                    }
                </span> */}
                {!noSearchGroups?
                    <div className="top-bottom-border" style={{marginBottom: "20px", marginTop: "-5px"}}>
                        {sortedGroups && sortedGroups.damage.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Damage</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.damage.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.cardGain.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Card Gain</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.cardGain.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.playerBuff.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Player/Card Buffs</h3>
                                <div className="card-pool-fill5">
                                    {sortedGroups.playerBuff.map((group) =>
                                        <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                            <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                            {group.name} *</h5>
                                        </NavLink>
                                        )
                                    }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.playerDebuff.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Player/Card Debuffs</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.playerDebuff.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.target.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Target</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.target.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.cardChange.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Card Change</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.cardChange.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.deckChange.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Deck Change</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.deckChange.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.boardChange.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Play-Area Change</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.boardChange.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.playerAction.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Player Action</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.playerAction.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.activationTiming.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Activation Timing</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.activationTiming.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.resolution.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Resolution Effect</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.resolution.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.effectType.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Effect Type</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.effectType.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                        {sortedGroups && sortedGroups.cost.length > 0?
                            <>
                                <h3 style={{fontWeight: "600", margin: "10px 12px"}}>Cost</h3>
                                <div className="card-pool-fill5">
                                {sortedGroups.cost.map((group) =>
                                    <NavLink to={`/groups/${group.id}`} className="nav-link2 glow2">
                                        <h5 style={{fontWeight: "400", margin: "5px 12px"}}>
                                        {group.name} *</h5>
                                    </NavLink>
                                    )
                                }
                                </div>
                            </>
                        :null}
                    </div>
                :null}
            </div>
    );
}

export default SearchGroupsPage;
